'use client';

import React, { createContext, useContext, useEffect, useState } from 'react';

import enJson from '../../content/en.json';

type EnMessages = typeof import('../../content/en.json');

interface IntlMessages extends EnMessages {}

export const LanguageContext = createContext<{
  locale: string;
  messages: IntlMessages | null;
}>({ locale: '', messages: null });

const getMsg = async (locale: string): Promise<IntlMessages> => {
  try {
    return (await import(`../../content/${locale}.json`)).default;
  } catch (error) {
    console.error(
      `Could not find messages for locale "${locale}", falling back to English.`
    );
    return enJson;
  }
};

type LanguageProviderProps = {
  locale: string;
  children: React.ReactNode;
};

export const LanguageProvider: React.FC<LanguageProviderProps> = ({
  locale,
  children,
}) => {
  const [messages, setMessages] = useState<IntlMessages | null>(enJson);

  useEffect(() => {
    const fetchData = async () => {
      const msg = await getMsg(locale);
      setMessages(msg);
    };

    fetchData();
  }, [locale]);

  return (
    <LanguageContext.Provider value={{ locale, messages }}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => useContext(LanguageContext);
